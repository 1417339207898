@font-face {
    font-family: 'BandLab Sans';
    src: url('./fonts/BandLabSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'BandLab Sans';
    src: url('./fonts/BandLabSans-Medium.ttf');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'BandLab Sans';
    src: url('./fonts/BandLabSans-Book.ttf');
    font-weight: 300;
    font-style: normal;
  }
